<template>
  <div>
    <section class="text-gray-700">
      <div class="container mx-auto">
        <div class="text-center">
          <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900">
            Latest Blogs
          </h1>
        </div>
      </div>
    </section>

    <Loader :loading="load"/>

    <p class="text-center text-base mt-6 mb-6" v-if="error">Sorry, We unable to load the posts, please refresh the
      page..</p>

    <div class="container px-1 mx-auto flex flex-wrap flex-col md:flex-row" v-if="!load">
      <div class="flex flex-col w-full md:w-4/6">
        <section class="text-gray-600 body-font overflow-hidden">
          <div class="container px-5 py-24 mx-auto">
            <div class="-my-8 divide-y-2 divide-gray-100">
              <div class="py-8 flex flex-wrap md:flex-nowrap" v-for="blog in posts" :key="blog">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <img :src="blog.image" style="width: 80%"/>
                  <span class="font-semibold title-font text-gray-700">{{ blog.meta.category }}</span>
                  <span class="mt-1 text-gray-500 text-sm">{{ blog.date }}</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">{{ blog.title }}</h2>
                  <p class="leading-relaxed">{{
                      blog.description
                    }}.</p>
                  <router-link  :to="getRoute(blog.id)"  class="text-indigo-500 inline-flex items-center mt-4">Learn More
                    <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round">
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Pagination -->
        <div class="flex flex-col items-center " v-if="!load">
          <div class="flex text-gray-700">
            <div v-if="page!==1"
                 class="h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                 @click="getNext(i, 'prev')">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-left w-6 h-6">
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
            <div class="flex h-12 font-medium rounded-full bg-gray-200">

              <div
                class="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full "
                :class="getPaginateClass(i)"
                v-for="i in 2" :key="i" @click="getNext(i)">{{ i }}
              </div>
            </div>
            <div v-if="page!==blogs.length"
                 class="h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                 @click="getNext(i, 'next')">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-chevron-right w-6 h-6">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-2/6 py-4">
        <h1 class="sm:text-2xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          Popular posts
        </h1>
        <router-link v-for="i in Math.min(blogs.length, 3)" :to="getRoute(i)"  :key="i">
          <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row ">
            <div class="w-full md:w-2/6 py-6 text-center">
              <img class="w-full md:w-4/5 z-50" :src="blogs[i].image"/>
            </div>
            <div class="flex flex-col w-full md:w-4/6 ">
              <h3 class="my-1  font-bold ">
                {{ blogs[i].title }}
              </h3>
              <p class="mb-4 my-2 ">
                {{ blogs[i].description }}
              </p>
            </div>
          </div>
          <hr/>
        </router-link>


      </div>
    </div>

  </div>
</template>

<script>
const axios = require('axios');
import url from "@/url";
import Loader from "@/components/Loader";

export default {
  name: "blog",
  components: {
    Loader,
  },
  data() {
    return {
      load: true,
      error: false,
      posts: [],
      errors: [],
      blogs: [],
      page: 1,
      postPerPage: 3,
    }
  },
  mounted() {
    this.getBlogs()
  },
  methods: {
    getRoute(i)
    {
      return "/blog/view/"+i
    },
    getPaginateClass(i = -1)
    {
      if (i === this.page) return "bg-purple-600 text-white"
    },
    getNext(number, type = "num") {
      if (type === "prev") this.page--
      else if (type === 'next') this.page++
      else this.page = number


      this.getPaginate()
    },
    getPaginate() {
      this.load = true
      this.posts = []
      let current = 0
      if (this.page !== 1) {
        current = (this.postPerPage + this.page) - 1
      }
      let j = 0
      for (let i = current; i < this.blogs.length; i++) {
        if (j === this.postPerPage) break
        this.posts.push(this.blogs[i])
        j++
      }
      this.load = false
    },
    getBlogs() {
      this.load = true
      this.posts = []
      axios.get(`${url}/posts.json?timestamp=${new Date().getTime()}`).then(response => {
        this.blogs = response.data
        let current = 0
        if (this.page !== 1) {
          current = this.postPerPage * this.page
        }
        let j = 0
        for (let i = current; i <= this.blogs.length; i++) {
          if (j === this.postPerPage) break
          this.posts.push(this.blogs[i])
          j++
        }
        this.load = false
      }).catch(err => {
        this.error = true
        this.errors = err
      })
    }
  }
}
</script>
